import React from "react";
import { Link } from "gatsby";
import EvorsLogo from "../../assets/images/logo.png";

export default function Logo({language}) {
  /**
   * @type {string}
   */
  const url = language === 'lv' ? '/' : '/' + language;

  return (
    <Link className={"logo"} to={url}>
      <img alt={"Evors"} src={EvorsLogo} className={"d-inline-block align-top"}/>
    </Link>
  )
}

import React from 'react';
import Header from './header';
import Footer from './footer';
import ClientsList from './ClientsList';
import Helmet from 'react-helmet';
import favicon from '../assets/images/favicon.png';
import CookieConsent from "react-cookie-consent";

import '../assets/style.scss';
import SubmitForm from "./ContactForm";

const Layout = ({children, language}) => (
  <div>
    <Helmet>
      <link rel="icon" href={favicon} />
      </Helmet>
    <Header language={language}/>
    <div className={'wrapper'}>
      {children}
      <div className={'wrapper-gray--light'}>
        <ClientsList/>
      </div>
    </div>
    <div className={"container"}>
      <SubmitForm/>
    </div>   
<CookieConsent
          location="bottom"
          buttonText="Piekrīti"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics">
Mēs izmantojam  <a className={'knopka knopka--white '} href="http://wf.evors.lv/informacija-par-sikdatnu-izmantosanu"> sīkdatnes</a>, lai padarītu lietošanas pieredzi ērtāku. Piekrīti sīkdatņu izmantošanai!
</CookieConsent>

    <Footer/>
  </div>

);

export default Layout;

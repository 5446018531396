import React, {Component} from 'react';

export default class ClientsListItem extends Component {
  render() {
    const {item} = this.props;

    return (
      <div className={'clients'}>
        <div className={'client'}>
          <div className={'client-bg'}>
            <img className={'client__logo'} srcSet={item.image}
                 alt={item.title}/>
          </div>
          <p className={'client-name'}>{item.title}</p>
        </div>
      </div>
    );
  }
}

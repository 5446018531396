import React, {Component} from "react";
import {Link} from "gatsby";
import LanguageSwitcher from "../LanguageSwitcher";

export default class NavBar extends Component {
    render() {
        const {menuItems, innerRef} = this.props;

        return (
            <div className={"navbar__list navbar--sm"} ref={innerRef}>
                {!!menuItems && !!menuItems.nodes && menuItems.nodes.map((menuItem, i) => {
                    if (menuItem.parentId) {
                        return null
                    }
                    const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url;
                    return (
                        <Link
                            key={i + menuItem.url}
                            to={path}
                            className={"navbar__link"}
                        >{menuItem.label}
                        </Link>
                    );
                })}
                <LanguageSwitcher />
            </div>
        );
    }
}

import React from "react"
import {Trans} from 'gatsby-plugin-react-i18next';
import FacebookLogo from "../assets/images/facebook_icon.png";
import InstagramLogo from "../assets/images/instagram-logo.png";

export default function Footer () {
  return (
    <footer className={"footer"}>
      <div className={"footer__copyright"}>
        <div className="container">
          <div className="social mb-2">
            <a className={'social-icon'} href="https://www.facebook.com/EvorsGroup/" target={'_blank'}
               rel={'noreferrer'}>
              <img alt={"Evors"} src={FacebookLogo} className={"d-inline-block align-top"}/>
               </a>
               <a className={'social-icon'} href="https://www.instagram.com/evor_s/" target={'_blank'}
               rel={'noreferrer'}>
              <img alt={"Evors"} src={InstagramLogo} className={"d-inline-block align-top"}/>
               </a>
                 <a  className={'knopka knopka--white'} href=" http://wf.evors.lv/kontakti/">Kontakti</a>
           
           </div>
             <p className={'mb-0'}>Copyright © www.evors.lv {new Date().getFullYear()} <Trans>footer.copyright</Trans></p>
        </div>
      </div>
    </footer>
  )
}

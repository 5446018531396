import React, {Component} from 'react';
import Carousel, {consts} from 'react-elastic-carousel';
import Arrow from '../../assets/images/arrow.svg';
import ClientsListItem from '../ClientsListItem';
import {graphql, StaticQuery} from 'gatsby';
import NewsImg from '../../assets/images/news.png';
import {Trans} from 'gatsby-plugin-react-i18next';

export default class ClientsList extends Component {
  /**
   * Next/prev slider buttons
   *
   * @param type
   * @param onClick
   * @param isEdge
   * @returns {*}
   */
  static buttons({type, onClick, isEdge}) {
    const btnType = type === consts.PREV ? 'left' : 'right';
    return (
      <button className={`rec rec-arrow rec-arrow-${btnType}`} onClick={onClick}
              disabled={isEdge}>
        <img alt={btnType} src={Arrow}/>
      </button>
    );
  }

  /**
   * Map graphql query response and build an array
   *
   * @param nodes
   * @returns {*}
   */
  static buildClientsList({nodes}) {
    return nodes.map((node) => {
      return {
        title: node.title,
        image: node.featuredImage
          ? node.featuredImage.node.remoteFile.childImageSharp.fluid.srcSet
          : NewsImg,
      };
    });
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
        fragment Thumbnail on File {
            childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        query Clients {
            allWpClient(
                sort: { fields: date, order: DESC }
            ) {
                nodes {
                    title
                    date
                    featuredImage {
                        node {
                            remoteFile {
                                ...Thumbnail
                            }
                        }
                    }
                }
            }
        }
  `}
        render={data => (
          <div className={'container'}>
            <h2 className={'text-center mt-60'}>
              <Trans>clients.title</Trans>
            </h2>

            <Carousel
              renderArrow={ClientsList.buttons}
              className={'clients-slider'}
              itemPadding={[30, 30, 30, 30, 30]}
              enableAutoPlay={true}
              breakPoints={[
                {width: 450, itemsToShow: 1, itemsToScroll: 1},
                {width: 650, itemsToShow: 2, itemsToScroll: 2},
                {width: 1000, itemsToShow: 4, itemsToScroll: 4},
              ]}
              autoPlaySpeed={4000}
              pagination={false}>
              {ClientsList.buildClientsList(data.allWpClient).map(
                (item, index) => <ClientsListItem key={index} item={item}/>)}
            </Carousel>
          </div>
        )}
      />
    );
  }
}

import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {useForm} from 'react-hook-form';
import FormField from '../FormField'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

export default function ContactForm() {
  const {t} = useTranslation();
  const WEBSITE_URL = process.env.WP_URL;
  const FORM_ID = process.env.CONTACT_FORM_ID; //Form id that provides Contact Form 7
  const [token, setToken] = useState(false) // store token
  const {register, errors, handleSubmit, reset, formState: {isSubmitting}} = useForm();

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const [isSubmitFailed, setIsSubmitFailed] = useState(false);

  // set timeout 3 seconds to remove error/success message.
  useEffect(() => {
    setTimeout(() => {
      setIsSubmitSuccessful(false)
    }, 3000);
  }, [isSubmitSuccessful])

  const getToken = async () => {
    if (token) {
      return token;
    }

    return await axios({
      method: 'post',
      url: `${WEBSITE_URL}/wp-json/jwt-auth/v1/token`,
      data: {
        username: process.env.SUBSCRIBER_USERNAME, // provide a user credential with subscriber role
        password: process.env.SUBSCRIBER_PASSWORD,
      },
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(response => {
      setToken(response.data.token)

      return response.data.token
    }).catch(error => console.error('Error', error))
  }

  const sendForm = async (body, bearerToken) => {
    const bodyFormData = new FormData();

    bodyFormData.append('fullName', body.fullName);
    bodyFormData.append('email', body.email);
    bodyFormData.append('phone', body.phone);
    bodyFormData.append('message', body.message);

    await axios({
      method: 'post',
      url: `${WEBSITE_URL}/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
      data: bodyFormData,
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'multipart/form-data'
      },
    }).then(response => {
      if (response.data.status === 'mail_sent') {
        setIsSubmitSuccessful(true)
        reset();
        return;
      }

      setIsSubmitFailed(true)
      console.log(response);
    }).catch(error => console.error(error))
  }

  const onSubmit = async (body) => {
    const bearerToken = token ? token : await getToken();

    if (!isSubmitting) {
      await sendForm(body, bearerToken)
    }
  };

  const data = {
    inputs: [
      {
        name: 'fullName',
        placeholder: t('form.name'),
        required: true,
        errorMsg: 'contact.form.required',
        validation: {required: true, min: 3},
        disabled: isSubmitting
      },
      {
        name: 'email',
        placeholder: t('form.email'),
        required: true,
        errorMsg: 'contact.form.email',
        validation: {required: true, pattern: /\S+@\S+\.\S+/i},
        disabled: isSubmitting
      },
      {
        name: 'phone',
        placeholder: t('form.phone'),
        required: false,
        errorMsg: 'contact.form.phone',
        validation: {required: true, pattern: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/i},
        disabled: isSubmitting
      },
      {
        name: 'message',
        placeholder: t('form.message'),
        required: true,
        errorMsg: 'contact.form.required',
        validation: {required: true, maxLength: 220},
        disabled: isSubmitting
      },
    ]
  };
  const {inputs} = data;

  return (
    <>
      <h2 className={"footer__title text-center"}>
        <Trans>contact.form.title</Trans>
      </h2>

      <form onSubmit={handleSubmit(onSubmit)} className={'form'} action='' method='post' id='contact-us'>
        {inputs.map((input, index) => {
          return (
            <div key={index}>
              <FormField input={input} innerRef={register(input.validation)}/>
              {errors[input.name] && (<span className={'error-msg'}><Trans>{input.errorMsg}</Trans></span>)}
            </div>
          )
        })}
        <button className={'btn btn--wide btn--bordered'} type='submit'>
          <Trans>submit</Trans>
        </button>
        {isSubmitSuccessful && (
          <div><span className="error-msg"><Trans>contact.form.success</Trans></span></div>
        )}
        {isSubmitFailed && (
          <div><span className="error-msg"><Trans>contact.form.error</Trans></span></div>
        )}
      </form>
    </>
  );
}

import React, {useState, useEffect, useRef} from 'react';
import Logo from './Logo';
import NavBar from '../components/NavBar';
import {graphql, useStaticQuery} from "gatsby";

export default function Header({language}) {
  const menuIdentifier = language === 'lv' ? 'PRIMARY' : 'PRIMARY___' + language?.toUpperCase();

  const {allWpMenu} = useStaticQuery(graphql`
      {
          allWpMenu {
              nodes {
                  name
                  locations
                  menuItems {
                      nodes {
                          label
                          url
                          parentId
                          connectedNode {
                              node {
                                  ... on WpContentNode {
                                      uri
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `);

  const wpMenu = allWpMenu.nodes.filter(menu => (menu.locations.filter(location => location === menuIdentifier).length > 0)).shift();

  const {
    ref,
    isVisible,
    setVisible,
  } = useComponentVisible(false);

  let bodyTag = typeof document !== `undefined` ? document.getElementsByTagName('body')[0] : null;

  if (bodyTag !== null) {
    bodyTag.style.overflow = (isVisible) ? 'hidden' : 'unset';
  }

  return (
    <div>
      <nav className={`navbar${isVisible ? ' active' : ''}`}>
        <div className={'container'}>
          <Logo language={language} />
          <button className={`navbar-toggler${isVisible ? ' d-none' : ''}`}
                  onClick={() => setVisible(true)}>
            <svg width="1em" height="1em" viewBox="0 0 16 16"
                 className="bi bi-list" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd"
                    d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>
          </button>
          <button className={`navbar-toggler${isVisible ? '' : ' d-none'}`}
                  onClick={() => setVisible(false)}>
            <svg width="1em" height="1em" viewBox="0 0 16 16"
                 className="bi bi-x" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd"
                    d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
              <path fillRule="evenodd"
                    d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
            </svg>
          </button>
          <NavBar menuItems={wpMenu?.menuItems} innerRef={ref}/>
        </div>
      </nav>
      <div className={`content-overlay${isVisible ? ' show' : ''}`}/>
    </div>
  );
}

function useComponentVisible(initialIsVisible) {
  const [isVisible, setVisible] = useState(
    initialIsVisible,
  );
  const ref = useRef(null);
  const handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      setVisible(false);
    }
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return {ref, isVisible, setVisible};
}
